@import './misc/perfect-scroll';
@import './misc/rc-tree-select';

.ntk-table {
  // width: 500px;
  // overflow-x: scroll;
  // margin-left: 15em;
  // overflow-y: visible;
  // padding: 0;
  overflow-x: scroll;

  .headcol {
    // position: absolute;
    // width: 20em;
    // left: 0;
    // top: auto;
    // border-top-width: 1px;
    // margin-top: -1px;
    // background: red;

    position: sticky;
    position: -webkit-sticky;
    background-color: #ffffff;
    width: 300px;
    min-width: 200px;
    max-width: 200px;
    left: 0px;
    z-index: 10;

    &.bg-grey {
      background-color: #d9d9d9;
    }

    &.narrow {
      width: 250px;
      min-width: 150px;
      max-width: 150px;
    }
  }
}

// div[class^="MUIDataTable-responsiveScrollMaxHeight"] {
//   max-height: initial;
// }

.highlight {
  background: #d8d8d8 !important;
}

.MuiInputBase-input {

  &::placeholder {
    color: #000000 !important;
    opacity: 0.8 !important;
  }
}

.sticky-table-wrap {
  position: relative;

  .sticky-table-header {
    position: absolute;
    top: 0;
    width: 100%;
    background: #f8fafc;
    z-index: 1150;
  }
}

.text-part-highlight {
  background-color: #dede1c;
}

.MuiTableCell-head {
  vertical-align: top !important;
}
/*
.clt,
.clt ul,
.clt li {
  position: relative;
}

.clt ul {
  list-style: none;
  padding-left: 32px;
}

.clt li::before,
.clt li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.clt li::before {
  border-top: 1px solid #000;
  top: 9px;
  width: 8px;
  height: 0;
}

.clt li::after {
  border-left: 1px solid #000;
  height: 100%;
  width: 0px;
  top: 2px;
}

.clt ul>li:last-child::after {
  height: 8px;
}
*/
.norms-tree {

  .tree-node {

    >td {
      border-top: 0px;
      border-bottom: 0px;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
    }
   
    td {

      &:first-child {
        position: relative;
        padding-left: 10px;

        &::before {
          content: "";
          position: absolute;
          left: 10px;
          border-top: 1px solid #000;
          top: 50%;
          width: 8px;
          height: 0;
        }

        &::after {
          content: "";
          position: absolute;
          left: 10px;
          border-left: 1px solid #000;
          height: 100%;
          width: 0px;
          top: 0px;
        }
      }
    }

    &.level-0 {
      td {
        &:first-child {
          padding-left: 22px;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    // &.level-0 {
    //   td {
    //     &:first-child {
    //       padding-left: 16px;

    //       &::before {
    //         width: 2px;
    //       }
    //     }
    //   }
    // }

    &.level-1 {
      td {
        &:first-child {
          padding-left: 30px;

          &::before {
            width: 16px;
          }
        }
      }
    }

    &.level-2 {
      td {
        &:first-child {
          padding-left: 38px;

          &::before {
            width: 24px;
          }
        }
      }
    }

    &.level-3 {
      td {
        &:first-child {
          padding-left: 46px;

          &::before {
            width: 32px;
          }
        }
      }
    }

    &.level-4 {
      td {
        &:first-child {
          padding-left: 54px;

          &::before {
            width: 40px;
          }
        }
      }
    }

    &.level-5 {
      td {
        &:first-child {
          padding-left: 60px;

          &::before {
            width: 46px;
          }
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }

  .tree-node.level-1.level-last {
    td {
      &:first-child {

        &::after {
          // height: 15px;
          height: 50%;
        }
      }
    }
  }

  .tree-details {

    >td {
      border-top: 0px;
      border-bottom: 0px;

      &:first-child {
        position: relative;
        background: #ffffff;

        &::after {
          content: "";
          position: absolute;
          left: 10px;
          border-left: 1px solid #000;
          height: 100%;
          width: 0px;
          top: 0px;
        }
      }
    }
  }
}